body {
  background-color: gray;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.container {
  width: 200px;
  height: 100%;
  top: 10%;
  left: 10%;
}

.sigContainer {
    width: 200px;
  height: 70px;
  margin: 10 auto;
  background-color: rgb(255 , 255, 255);
  border-width: .1em;
  border-color: black;
  border-style: solid ;
}

.sigPad {
  width: 200px;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}
